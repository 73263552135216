@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'MyFont';
  src: url('./assets/fonts/Garamond Bold.ttf') format('truetype'); /* Adjust the path as needed */
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'MyUrdu';
  src: url('./assets/fonts/Jameel Noori Nastaleeq Kasheeda.ttf') format('truetype'); /* Adjust the path as needed */
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'MyUrduSecond';
  src: url('./assets/fonts/Jameel Noori Nastaleeq Regular.ttf') format('truetype'); /* Adjust the path as needed */
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Myarial';
  src: url('./assets/fonts/arial.ttf') format('truetype'); /* Adjust the path as needed */
  font-weight: bold;
  font-style: normal;
}

* {
  font-family: 'Poppins', sans-serif;
}

.main_title {
  @apply text-primary text-2xl font-medium;
}

h2 {
  @apply text-black text-lg   font-extrabold;
}

.param {
  @apply text-white text-sm  font-medium;
}

/* Scrollbar Styling */
.overflow-x-auto::-webkit-scrollbar {
  /* width:2px !important; */
  /* display: none !important; */
}
.overflow-y-auto::-webkit-scrollbar {
  display: none !important;
}
.overflow-x-auto::-webkit-scrollbar-track {
  background-color: #ebebeb;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

.quill {
  padding-bottom: 8px !important;
}

.quill .ql-toolbar {
  border: 0 !important;
}

.ql-container {
  border: 0 !important;
  background-color: white;
  margin: 10px !important;
  height: 130px !important;
}

.css-13cymwt-control,
.css-t3ipsp-control {
  padding: 7px !important;
  background-color: rgb(246 246 246) !important;
  color: #262262 !important;
  border: none !important;
  margin-top: 8px;
}

.overflow-x-auto::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #f6f6f6 !important;
}

.date_picker {
  background-color: red !important;
  padding: 6px !important;
}

.duration-300 button:hover {
  background-color: lightgray !important;
  border-radius: 50% !important;
  color: #fff !important;
}

.react-datepicker__day--selected {
  background-color: #262262 !important;
}

.react-datepicker-wrapper {
  width: 100% !important;
}
