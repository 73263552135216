/* styles.css */
.html-content {
  margin: 0;
  padding: 20px; /* Adjust padding as needed */
  line-height: 1.6; /* Improves readability */
  font-family: Arial, sans-serif; /* Use a clean font */
}

/* You can add more styles for specific HTML elements if needed */
.html-content h1,
.html-content h2,
.html-content h3 {
  margin-top: 20px; /* Adjust heading spacing */
  color: #333; /* Change heading color */
}

.html-content p {
  margin-bottom: 10px; /* Space between paragraphs */
}

.react-tel-input input {
  height: 50px !important;
  background-color: #f6f6f6 !important;
  border: none !important;
}

.react-tel-input .flag-dropdown {
  border: none !important;
}
